// production
export const apiBaseUrl = "https://fxtapi.pinesucceed.com/api/account"
export const axiosBaseUrl = "https://fxtapi.pinesucceed.com/"
export const reCaptchaSiteKey = "6LfYC4knAAAAAJ6EBJLijTDdpTXFyKJEjX1qN0p_"


// development
export const apiBaseUrlDev = "http://localhost:3003/api/account"
export const axiosBaseUrlDev = "http://localhost:3003/"
export const reCaptchaSiteKeyDev = "6LdCpJIjAAAAAPGk3n6CKmkKiuUPrdDUaulKVenV"

